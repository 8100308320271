<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}}</h1>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({name: 'elkp/ContractListView'})">
        <VIcon v-text="'mdi-arrow-left'" />
        Назад к списку договоров
      </VBtn>
    </div>
    <EmployeeList
        :items="employees.items"
        :count="employees.count"
        :page="page"
        :size="size"
        :loading="loading"
        @click:row="({ id }) => $router.push({ name: 'elkp/EmployeeDetailView', params: { contract: 'employee', employee: id }})"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { mapGetters, mapActions } from 'vuex';
import EmployeeList from '@/components/elkp/EmployeeList/EmployeeList';
export default {
  name: 'EmployeeListView',
  components: {
    EmployeeList,
  },
  props: {
    page: { type: Number, default: 1 },
    size: { type: Number, default: 20 },
    filter: { type: Object },
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      loading: 'elkp/pending',
      employees: 'elkp/employees',
    }),
  },
  methods: {
    ...mapActions({
      fetchEmployeeList: 'elkp/fetchEmployeeList',
    }),
    onUpdateEmployees: debounce(function() {
      const { page, size, filter } = this;
      this.fetchEmployeeList({ page, size, filter });
    }, 500),
  },
  watch: {
    page: function() {
      this.onUpdateEmployees();
    },
    size: function() {
      this.onUpdateEmployees();
    },
    filter: {
      deep: true,
      immediate: true,
      handler: function() {
        this.onUpdateEmployees();
      }
    },
  },
}
</script>

<style module lang="scss">
.root {}
</style>
